* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

img{
  pointer-events: none;
  user-select: none;
}